import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 10vw;
`;

const Column = styled.div`
  justify-content: center;
  width: 20vw;
  margin: 25px 0;
`;

const Shop = styled(Link)`
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
  color: var(--lavender);

  &:hover {
    color: var(--lavender);
  }

  &:focus-visible {
    outline-color: var(--lavender);
  }
`;

const CitiesList = ({ cities }) => {
  const citiesMock = [
    { city: "Copenhagen" },
    { city: "London" },
    { city: "Paris" },
    { city: "Barcelona" },
    { city: "Copenhagen" },
    { city: "London" },
    { city: "Paris" },
    { city: "Barcelona" },
    { city: "Copenhagen" },
    { city: "London" },
    { city: "Paris" },
    { city: "Barcelona" },
    { city: "Copenhagen" },
    { city: "London" },
    { city: "Paris" },
    { city: "Barcelona" },
    { city: "Copenhagen" },
    { city: "London" },
    { city: "Paris" },
    { city: "Barcelona" },
    { city: "Copenhagen" },
    { city: "London" },
    { city: "Paris" },
    { city: "Barcelona" },
  ];
  return (
    <>
      <Container>
        {/* Filter cities by their first letter to diplay them in the div with the same letter */}
        {cities.map((item) => {
          return (
            <Column key={item.city}>
              <Shop to={`/shops/${item.city.toLowerCase()}`}>{item.city}</Shop>
            </Column>
          );
        })}
      </Container>
    </>
  );
};

export default CitiesList;
