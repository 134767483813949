import React from "react";

const ThankYou = ({ show, addHandle }) => {
  const width = window.innerWidth;
  return (
    <div
      className={show ? "add-background" : "add-disabled"}
      onClick={addHandle}
    >
      {width > 540 ? (
        <h1 className="add-header">
          thank you for
          <br />
          investing your time
          <br />
          and helping others
          <br />
          to shop more
          <br />
          consciously!
        </h1>
      ) : (
        <h1 className="add-header thanks-header">
          thank you
          <br />
          for investing
          <br />
          your time
          <br />
          and helping
          <br />
          others to
          <br />
          shop more
          <br />
          consciously!
        </h1>
      )}
      <div className="hidden-row"></div>
    </div>
  );
};

export default ThankYou;
