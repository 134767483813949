import React from "react";
import styled from "styled-components";

const SegmentedControl = ({ types, enabledTypes, selectedType, setType }) => {
  return (
    <SegmentedControlContainer>
      {enabledTypes &&
        types?.map((type, index) => {
          return (
            <StoreType
              selected={selectedType === type.id}
              enabled={enabledTypes[index]}
              key={index}
              onClick={() => enabledTypes[index] && setType(type.id)}
              aria-disabled={!enabledTypes[index]}
            >
              {type.name.toLowerCase()}
            </StoreType>
          );
        })}
    </SegmentedControlContainer>
  );
};

export default SegmentedControl;

const SegmentedControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  margin: 20px 0 20px 0;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

const StoreType = styled.div`
  white-space: nowrap;
  color: ${(props) => (props.enabled ? "var(--blue)" : "var(--lavender)")};
  font-weight: 600;
  font-size: 18px;
  margin: 1px 15px;
  padding: 4px 10px;
  width: 100%;
  background-color: ${(props) => props.selected && "var(--lavender)"};
  border-radius: 20px;
  cursor: ${(props) => (props.enabled ? "pointer" : "default")};
`;
