import React, { useState } from "react";

import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";

import NewInfoWindow from "./NewInfoWindow";
import Api from "../../RestApi";
import MapHeader from "./MapHeader";

import { MAP_STYLE } from "../../constants";

import "./NewMap.css";

const libraries = ["places"];
const mapContainerStyle = {
  height: "calc(100vh - 60px)",
  width: "100vw",
};

const MAPS_BOUNDS = {
  north: 85,
  south: -80,
  west: -180,
  east: 180,
};

const options = {
  styles: MAP_STYLE,
  disableDefaultUI: true,
  zoomControl: true,
  clickableIcons: false,
  minZoom: 2,
  restriction: {
    latLngBounds: MAPS_BOUNDS,
    strictBounds: true,
  },
};

const clusterStyles = [
  {
    height: 35,
    width: 35,
    url: "/static/media/ZoomOutIconBlue.c9b7bd09.svg",
  },
];

const center = {
  lat: 51.259998,
  lng: 11.3833318,
};

const defaultZoom = 4.6;

function Map({ menuHandle }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const [markers, setMarkers] = useState([]);
  const [storeTypes, setStoreTypes] = useState([]);
  const [selectedShop, setSelectedShop] = useState({ id: "" });
  const [photos, setPhotos] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchMarkers = async () => {
    const { stores, types } = await Api.getMarkers();
    setMarkers(stores);
    setStoreTypes(types);
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchMarkers();
  }, []);

  const fetchPhotos = async (shopId) => {
    const pictures = await Api.getPhotos(shopId);
    setPhotos(pictures);
  };

  const fetchHours = async (shop) => {
    const hours = await Api.getHours(shop.id);
    setMarkers(
      markers.map((el) =>
        el.id === shop.id ? { ...el, ...{ hours: hours } } : el
      )
    );
    setSelectedShop({ ...shop, ...{ hours: hours } });
  };

  function resetSelectedShop() {
    setSelectedShop({ id: "" });
  }

  const [isMapCentered, setIsMapCentered] = useState(true);

  const [userLocation, setUserLocation] = useState();

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: "geolocation" }).then((res) => {
        Locate();
        res.onchange = () => {
          res.state === "denied" ? setUserLocation() : Locate();
        };
      });
    } else if (navigator.geolocation) {
      Locate();
    }
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(13);
    setIsMapCentered(false);
  }, []);

  const Locate = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        panTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setIsMapCentered(true);
      },
      () => {},
      { maximumAge: 600000, timeout: 5000, enableHighAccuracy: true }
    );
  };

  const mapReset = () => {
    if (!isMapCentered) {
      if (userLocation) {
        panTo(userLocation);
      } else {
        mapRef.current.panTo(center);
        mapRef.current.setZoom(defaultZoom);
      }
      setIsMapCentered(true);
    }
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
      <MapHeader
        panTo={panTo}
        mapReset={mapReset}
        isMapCentered={isMapCentered}
        menuHandle={menuHandle}
      />

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={defaultZoom}
        npm
        run
        center={center}
        options={{
          ...options,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM,
          },
        }}
        onClick={() => {
          selectedShop.id !== "" && resetSelectedShop();
          photos && setPhotos({});
        }}
        onLoad={onMapLoad}
        onCenterChanged={() => setIsMapCentered(false)}
        onZoomChanged={() => setIsMapCentered(false)}
      >
        {!isLoading && (
          <MarkerClusterer
            averageCenter
            minimumClusterSize={1}
            maxZoom={10}
            styles={clusterStyles}
            clusterClass="pins-cluster"
          >
            {(clusterer) =>
              Object.keys(markers).map((shopKey, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: markers[shopKey].latitude,
                    lng: markers[shopKey].longitude,
                  }}
                  onClick={() => {
                    if (mapRef.current.getZoom() >= 10) {
                      !markers[shopKey].hours
                        ? fetchHours(markers[shopKey])
                        : setSelectedShop(markers[shopKey]);
                      fetchPhotos(markers[shopKey].id);
                    }
                  }}
                  icon={{
                    url: storeTypes[shopKey].pin_url,
                    scaledSize: new window.google.maps.Size(25, 45),
                  }}
                  clusterer={clusterer}
                >
                  {selectedShop.id === markers[shopKey].id ? (
                    <InfoWindow
                      position={{
                        lat: selectedShop.latitude,
                        lng: selectedShop.longitude,
                      }}
                    >
                      <NewInfoWindow
                        shop={selectedShop}
                        color={storeTypes[shopKey].background_color}
                        photos={photos}
                        closeClick={resetSelectedShop}
                      />
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))
            }
          </MarkerClusterer>
        )}
      </GoogleMap>
    </div>
  );
}

export default React.memo(Map);
