import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";

import SidebarData from "./SidebarData";
import closeCross from "../img/buttons/CrossDouble.svg";

import "./NewMenu.css";

const BurgerMenu = ({ menuHandle }) => {
  return (
    <div className="menu-background">
      <Image
        className="burger-close"
        alt="close"
        src={closeCross}
        onClick={menuHandle}
      />
      <Container className="justify-content-center">
        {SidebarData.map((item, index) => {
          return (
            <Row className="menu-row" key={index}>
              <Col>
                <Link className="menu-link" to={`/pages${item.path}`}>
                  {item.title}
                </Link>
              </Col>
            </Row>
          );
        })}
        <Row className="menu-row" style={{ height: 30 }} />
        <Row className="menu-row">
          <Col>
            <Link className="menu-link" to="/shops">
              Shops
            </Link>
          </Col>
        </Row>
        <Row className="mb-sm-1 hidden-row"></Row>
      </Container>
    </div>
  );
};

export default BurgerMenu;
