import React from "react";
import Image from "react-bootstrap/Image";

import plusButton from "../img/buttons/PlusLavender.svg";

const About = () => {
  return (
    <>
      <h1 className="section-header">about</h1>
      <p className="section-p">
        Conscious Sweater is your easy way to buy sustainable fashion. We don’t
        want you to shop more! When you need to shop, however, we help you to do
        it consciously.
      </p>

      <p>
        <span className="section-span">
          ‘We created a map helping you to find places that offer consciously
          made clothes.’
        </span>
      </p>

      <p className="section-p">
        By creating such a map of{" "}
        <span className="bold-span">sustainable fashion & vintage shops</span>{" "}
        we want to help change your and your friends’ shopping habits. Changing
        your habits? Ugh, that sounds annoying, we know! That’s why we made the
        Conscious Sweater map easy and fun to use.
      </p>

      <p className="section-p">
        The core idea is simple: a map that helps you to easily find places that
        offer{" "}
        <span className="bold-span">
          ethically made clothes, vintage pieces, creations from local designers
        </span>{" "}
        (who follow the principles of sustainability) and{" "}
        <span className="bold-span">
          rental services. As many as possible, in one map.
        </span>{" "}
        To get them all, worldwide, we ask you to contribute by suggesting
        sustainable shops that you think belong to the Conscious Sweater map.
        Just press the{" "}
        <Image className="section-img" alt="plus button" src={plusButton} />{" "}
        button on the main screen & start adding!
      </p>

      <h2 className="section-h2">
        WHO ARE WE KNITTING THE CONSCIOUS SWEATER FOR?
      </h2>

      <p className="section-p">
        We developed this map for our friends who keep asking where to shop for
        ethically made clothes, and for everyone else who is interested in
        wearing sustainable fashion. You might be a{" "}
        <span className="bold-span">sustainable fashionista</span> looking for
        new stores, a sustainable fashion enthusiast{" "}
        <span className="bold-span">exploring a new city</span> or a{" "}
        <span className="bold-span">sustainable fashion newcomer</span> in need
        of direction.
      </p>

      <p>
        <span className="section-span">
          ‘We at Conscious Sweater want a world where sustainable fashion is the
          new norm.’
        </span>
      </p>

      <p className="section-p">
        We want to make sustainable and fair fashion more accessible to you as
        well as to enable global change in fashion consumption.
      </p>
    </>
  );
};

export default About;
