import React, { useState } from "react";
import Image from "react-bootstrap/Image";

const HoursDiplay = ({ shopHours, src }) => {
  const [showList, setShowList] = useState(false);
  const day = new Date().getDay();
  return (
    <div id="hours-display">
      <ul id="hours-list">
        {shopHours.map((hours, index) => (
          <li
            key={index}
            className={
              showList || findTodayHours(day, index)
                ? "hours-elements"
                : "hours-elements list"
            }
          >
            {hours}
          </li>
        ))}
      </ul>
      <Image
        className={showList ? "hours-arrow-active" : "hours-arrow"}
        src={src}
        alt="arrow"
        onClick={() => setShowList(!showList)}
      />
    </div>
  );
};

function findTodayHours(day, index) {
  if (day === 0 && index === 6) {
    return true;
  } else if (day - 1 === index) {
    return true;
  }
  return false;
}

export default HoursDiplay;
