import React from "react";
import { Link } from "react-router-dom";

const CitiesFilter = ({ alphabet, cities }) => {
  const citiesFirstLetter = cities.map((item) => item.city[0].toUpperCase());
  const activeLetters = alphabet.filter((letter) =>
    citiesFirstLetter.includes(letter)
  );
  return (
    <div id="sidenav">
      {alphabet.map((letter) => {
        return (
          <Link
            className={
              activeLetters.includes(letter)
                ? "sidenav-link"
                : "sidenav-link sidenav-disabled"
            }
            key={letter}
            to={`/shops#${letter}`}
          >
            {letter}
          </Link>
        );
      })}
    </div>
  );
};

export default CitiesFilter;
