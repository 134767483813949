import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

import SignUpPage from "./components/sign-up/SignUpPage";
import SubscribeThankYou from "./components/sign-up/SubscribeThankYou";
import NotFoundPage from "./components/Nav/NotFoundPage";
import BurgerMenu from "./components/Nav/NewMenu";
import MainMap from "./components/map/MainMap";
import AddPage from "./components/map-btn/AddPage";
import Help from "./components/map-btn/Help";
import CsSection from "./components/pages/CsSection";
import ShopsListUrls from "./components/shops-list/shopsListUrls";

import addButton from "./components/img/buttons/PlusBlue.svg";
import helpButton from "./components/img/buttons/QuestionMark.svg";
import Close from "./components/img/buttons/CrossDouble.svg";

import "./App.css";

const App = () => {
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/session`)
      .then((response) => response.json())
      .then((data) => {
        setFirstOpen(!data.subscribed);
      });
  }, []);

  const [firstOpen, setFirstOpen] = useState(true);

  const [menuShow, setMenuShow] = useState(false);
  function menuOpenHandle() {
    setMenuShow(true);
  }
  function menuCloseHandle() {
    setMenuShow(false);
  }

  const [helpShow, setHelpShow] = useState(false);
  function helpOpenHandle() {
    setHelpShow(true);
  }
  function helpCloseHandle() {
    setHelpShow(false);
  }

  const [addShow, setAddShow] = useState(false);
  function addOpenHandle() {
    setAddShow(true);
  }
  function addCloseHandle() {
    setAddShow(false);
  }

  const [isSubmitted, setIsSubmitted] = useState(false);

  function formSubmit() {
    setIsSubmitted(true);
  }
  function formReset() {
    setIsSubmitted(false);
  }

  const [subscribeShow, setSubscribeShow] = useState(true);

  return (
    <BrowserRouter>
      <AppContainer>
        <Switch>
          <Route exact path="/">
            {firstOpen && (
              <div id="sign-up-background">
                <div id="lavender-square" />
                <Image
                  id="signup-close"
                  alt="Subscribe close"
                  src={Close}
                  onClick={() => setFirstOpen(false)}
                />
                <SignUpPage closeHandle={() => setFirstOpen(false)} />
              </div>
            )}
            <MainMap
              addSrc={addButton}
              helpSrc={helpButton}
              helpHandle={helpOpenHandle}
              addHandle={addOpenHandle}
              formHandle={formReset}
              menuHandle={menuOpenHandle}
            />
            {menuShow && <BurgerMenu menuHandle={menuCloseHandle} />}
            <Help show={helpShow} helpHandle={helpCloseHandle} />
            <AddPage
              show={addShow}
              isSubmitted={isSubmitted}
              addHandle={addCloseHandle}
              formSubmit={formSubmit}
            />
          </Route>
          <Route
            path="/pages"
            render={() => (
              <CsSection
                menuCloseHandle={menuCloseHandle}
                menuOpenHandle={menuOpenHandle}
                addOpenHandle={addOpenHandle}
                addCloseHandle={addCloseHandle}
                addShow={addShow}
                isSubmitted={isSubmitted}
                formReset={formReset}
                formSubmit={formSubmit}
              />
            )}
          />
          <Route
            path="/shops"
            render={() => <ShopsListUrls menuCloseHandle={menuCloseHandle} />}
          />
          <Route path="/subscribe-to-our-newsletter">
            <Helmet>
              <title>Conscious Sweater map - Subscribe to our newsletter</title>
              <meta
                name="description"
                content="Subscribe to our newsletter to stay informed on our latest sustainable fashion update."
              />
            </Helmet>
            <div id="sign-up-background">
              <Link to="/pages#community" title="Our sustainable fashion map">
                <Image
                  id="back-to-map"
                  alt="Back to our sustainable fashion map"
                  src={Close}
                  onClick={() => setFirstOpen(false)}
                />
              </Link>
              {subscribeShow ? (
                <SignUpPage closeHandle={() => setSubscribeShow(false)} />
              ) : (
                <SubscribeThankYou />
              )}
            </div>
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AppContainer>
    </BrowserRouter>
  );
};

const AppContainer = styled.div`
  /* display: flex;
  flex-direction: column; */
  overflow: hidden;
`;

export default App;
