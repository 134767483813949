import React from "react";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Helmet } from "react-helmet";

import csLogo from "../img/CSLogo_Vector.svg";

import "./NotFound.css";

const NotFoundPage = () => {
  return (
    <>
      <Helmet
        title="Conscious Sweater - Not found"
        meta={[
          { name: "ROBOTS", content: "NOINDEX" },
          {
            name: "description",
            content: "Conscious Sweater - Page not found",
          },
        ]}
      />
      <div id="not-found-div">
        <Image id="not-found-logo" alt="Conscious Sweater logo" src={csLogo} />
        <p className="not-found-text">
          We cannot find what you are looking for.
        </p>
        <p className="not-found-text">
          Check the URL or click the button below.
        </p>

        <div>
          <Link
            className="not-found-link"
            to="/"
            title="Our sustainable fashion map"
          >
            <button className="not-found-btn">Back to the MAP</button>
          </Link>
        </div>
        <div id="hidden-row-404" />
      </div>
    </>
  );
};

export default NotFoundPage;
