import React, { useState } from "react";
import { Image } from "react-bootstrap";

import Map from "./NewMap";
import MapMobile from "./NewMapMobile";

import "./MainMap.css";

const MainMap = ({
  addSrc,
  helpSrc,
  helpHandle,
  addHandle,
  formHandle,
  menuHandle,
}) => {
  const width = window.innerWidth;
  const [selectedShop, setSelectedShop] = useState({ id: "" });
  function selectShop(object) {
    setSelectedShop(object);
  }
  return (
    <>
      {width > 540 ? (
        <Map menuHandle={menuHandle} />
      ) : (
        <MapMobile
          menuHandle={menuHandle}
          selectedShop={selectedShop}
          selectShop={selectShop}
        />
      )}
      {width > 500 ? (
        <MapButton
          addHandle={addHandle}
          formHandle={formHandle}
          addSrc={addSrc}
          helpHandle={helpHandle}
          helpSrc={helpSrc}
        />
      ) : (
        selectedShop.id === "" && (
          <MapButton
            addHandle={addHandle}
            formHandle={formHandle}
            addSrc={addSrc}
            helpHandle={helpHandle}
            helpSrc={helpSrc}
          />
        )
      )}
    </>
  );
};

const MapButton = ({ addHandle, formHandle, addSrc, helpHandle, helpSrc }) => {
  return (
    <>
      <button
        className="map-btn"
        onClick={() => {
          addHandle();
          formHandle();
        }}
      >
        <Image className="map-btn-img" alt="add button" src={addSrc} />
      </button>
      <button className="map-btn btn-top" onClick={helpHandle}>
        <Image className="map-btn-img" alt="help button" src={helpSrc} />
      </button>
    </>
  );
};

export default MainMap;
