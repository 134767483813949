import React from "react";

import ShopsListHeader from "./shopsListHeader";
import CitiesListMobile from "./citiesListMobile";
import CitiesList from "./citiesList";
import CitiesFilter from "./citiesFilter";

const CitiesPage = ({ cities, menuCloseHandle }) => {
  const width = window.innerWidth;
  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  return (
    <>
      <ShopsListHeader title="CITY" menuCloseHandle={menuCloseHandle} />
      {width > 540 ? (
        <CitiesList cities={cities} />
      ) : (
        <CitiesListMobile alphabet={alphabet} cities={cities} />
      )}
      {width < 540 && <CitiesFilter alphabet={alphabet} cities={cities} />}
    </>
  );
};

export default CitiesPage;
