import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { pinsDescription } from "../map-btn/pinDescription";

const Sustainability = ({ width }) => {
  return (
    <>
      <h1 className="section-header">Sustainability</h1>
      <p>
        <span className="section-span">
          ‘The level of sustainability in the fashion industry mirrors the
          values we have as a society.’
        </span>
      </p>
      <p className="section-p">
        Is style & comfort more important to us than next generations’ quality
        of life? Do we choose retail therapy over the well-being of developing
        countries? Some people might not care and Conscious Sweater won’t change
        that. We can however help those of you who do care.
      </p>
      <p className="section-p">
        The best thing to make the fashion industry more sustainable is to quit
        shopping. This seems to be way too big of a change right now, but we can
        inspire you to make little changes to start with. Therefore we only ask
        you to consider each of your fashion purchases twice. When the need to
        shop wins then choose vintage or brands producing clothes ethically &
        ecologically rather than fast fashion and use our map to find them
        easily.
      </p>
      <h2 className="section-h2">
        WHAT KIND OF SHOPS CAN MAKE IT IN CONSCIOUS SWEATER MAP
      </h2>
      <p className="section-p">
        We add shops selling vintage fashion, sustainable fashion boutiques,
        shops selling local produce, fashion rental services and we also have a
        special category for shops selling conventional fashion together with a
        selection of sustainable brands.
      </p>
      <Container>
        {pinsDescription.map((item, index) => {
          return (
            <Row key={index} className="justify-content-center row-pin">
              <Col xs={"auto"} className="img-sustainable">
                <Image className="section-pin" alt={item.alt} src={item.src} />
              </Col>
              <Col xs={10} s={9} className="col-sustainable">
                {width > 540 ? item.susText : item.textMobile}
              </Col>
            </Row>
          );
        })}
      </Container>
      <h2 className="section-h2">HOW WE SELECT THE SHOPS</h2>
      <p className="section-p">
        We only allow shops focusing on{" "}
        <span className="bold-span">
          fashion that doesn’t harm the planet & people
        </span>{" "}
        to be on our map. That means we look into: 1. how the fashion is made
        from an <span className="bold-span">ecological perspective</span> and 2.
        who makes the clothes and under what conditions:{" "}
        <span className="bold-span">was it produced ethically?</span>
      </p>
      <h2 className="section-h2">ECOLOGICAL ASPECT</h2>
      <p className="section-p">
        When scanning{" "}
        <span className="bold-span">sustainable fashion shops</span> for
        ecological aspects we want to know:
      </p>
      <p className={width > 540 ? "center-p" : "section-p"}>
        What are the fabrics made of and how?
        <br />
        Do they not contain toxic chemicals?
        <br />
        Do factories treat their wastewater well?
      </p>
      <p className="section-p">
        We first look at the shops’ philosophy & values and then we investigate
        what brands it sells. Knowing the history of different brands worldwide
        helps us recognise which of them are really committed to sustainability
        in the long term. Then we focus on looking for internationally
        recognised certifications such as GOTS & Econyl etc. Those prove the
        brand and the shop are choosing the best & most sustainable material
        alternative possible. Still, those certifications are not available for
        all types of fabrics and, therefore, we also need to trust brands and
        their statements about their commitments.
      </p>
      <p className="section-p">
        With{" "}
        <span className="bold-span">
          sustainable brands, designers and local fashion
        </span>
        , we ask the same questions and investigate the same ecological aspects
        of the used materials. Given that those might be very small businesses
        rather than internationally recognised brands, we often get in touch
        directly with them to check on their practices.
      </p>
      <p className="section-p">
        We consider{" "}
        <span className="bold-span">vintage shops and rental services</span> as
        having almost no ecological impact. Those shops give already used and
        tossed clothes a new life, so no new resources are used. The only
        downside here is the transport between countries usually within Europe
        or the US. Compared to the production & transport of new clothes, the
        impact is however still very low. From an ecological point of view, we
        always recommend & support choosing used clothes first.
      </p>
      <h2 className="section-h2">ETHICAL ASPECT</h2>
      <p className="section-p">
        We don’t want our clothes to be made by{" "}
        <span className="bold-span">underpaid people</span>, who cannot afford
        to live a decent life, don’t work in safe conditions and we especially
        don’t want <span className="bold-span">clothes made by children.</span>
      </p>
      <p className="section-p">
        We, therefore, check{" "}
        <span className="bold-span">sustainable & local shops’</span> values and
        see how much they care. It’s very common that owners of brands and shops
        personally visit the factories, know the owners and meet some of the
        people making the clothes. Some brands are very transparent and share
        their garment supply chain, which means we can look at the specific
        factory where the pieces were made. That is a very positive sign proving
        that brands: 1. know all of the information about their suppliers
        (that’s not the case for fast fashion brands) and 2. don’t have anything
        to hide. Some certificates as GOTS and Fairtrade focus on the social
        aspect of the production as well. They guarantee fair wages and safe
        conditions for the workers, which is an essential part of sustainable
        fashion.
      </p>
      <p className="section-p">
        As for{" "}
        <span className="bold-span">vintage shops and rented fashion,</span> we
        again consider them to create mostly an ethically positive impact.
      </p>
    </>
  );
};

export default Sustainability;
