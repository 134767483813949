import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Image, Carousel } from "react-bootstrap";

import Api from "../../RestApi";

import HoursDiplay from "../map/hoursDisplay";
import ShopsListHeader from "./shopsListHeader";

import EmptyShop from "../img/pins/sustainable_fashion.png";
import HoursLavender from "../img/nav/OpeningHoursLavender.svg";
import WebLavender from "../img/nav/WebsiteLavender.svg";
import NavLavender from "../img/nav/NavigationLavender.svg";
import ArrowLavender from "../img/nav/Arrowlavender.svg";

const SingleShopPage = ({ menuCloseHandle }) => {
  const { id } = useParams();
  const [shop, setShop] = useState();
  const [type, setType] = useState();
  const [photos, setPhotos] = useState();
  useEffect(() => {
    const getShop = async () => {
      const { shop: shopData, type: typeData } = await Api.getData(
        `/api/single-shop?shop_id=${id}`
      );
      const photoData = await Api.getPhotos(id);
      const hoursData = await Api.getHours(id);
      setShop({ ...shopData, ...{ hours: hoursData } });
      setType(typeData);
      setPhotos(photoData);
    };
    getShop();
  }, [id]);

  return (
    <>
      {photos && (
        <>
          <ShopsListHeader
            title={shop.name}
            menuCloseHandle={menuCloseHandle}
          />
          <div id="shop-single-page">
            <div id="shop-header-div">
              <div id="shop-pin-div">
                <Image
                  className="single-shop-pin"
                  src={type.pin_url}
                  alt="pin"
                />
              </div>
              <div id="shop-header-text">
                <h1 id="shop-header-name">{shop.name}</h1>
                {shop.location && (
                  <h2 id="shop-header-location">{shop.location}</h2>
                )}
                <h2 id="shop-header-description">{shop.description}</h2>
              </div>
            </div>
            <Carousel id="shop-carousel" interval={null}>
              {photos.length ? (
                Object.keys(photos).map((imageKey, index) => (
                  <Carousel.Item className="carousel-item" key={index}>
                    <Image
                      className="shop-image"
                      src={
                        process.env.REACT_APP_BASE_URL +
                        photos[imageKey].image_file
                      }
                      alt="shop-picture"
                    />
                  </Carousel.Item>
                ))
              ) : (
                <Carousel.Item className="carousel-item">
                  <Image
                    className="shop-image"
                    src={EmptyShop}
                    alt="shop-picture"
                  />
                </Carousel.Item>
              )}
            </Carousel>
            <p className="shop-audiences-p">
              {shop.audiences.map((audience, i) => [i > 0 && " | ", audience])}
            </p>
            <div className="shop-section">
              <h3 className="shop-section-header">INFO</h3>
              <div
                className={
                  shop.hours.status === "error"
                    ? "shop-wrapper"
                    : "hours-wrapper"
                }
              >
                <Image className="shop-nav" src={HoursLavender} alt="clock" />
                {shop.hours.status === "error" ? (
                  <p className="shop-hours">Hours not available</p>
                ) : (
                  <HoursDiplay shopHours={shop.hours} src={ArrowLavender} />
                )}
              </div>
              <div className="shop-wrapper">
                <Image className="shop-nav" src={WebLavender} alt="website" />
                <Link
                  className="shop-link"
                  to={{ pathname: `https://${shop.website}` }}
                  target="_blank"
                >
                  {shop.website.indexOf(".com") !== -1
                    ? shop.website.slice(0, shop.website.indexOf(".com") + 4)
                    : shop.website}
                </Link>
              </div>
              <div className="shop-wrapper">
                <Image
                  className="shop-nav"
                  src={NavLavender}
                  alt="navigation"
                />
                <Link
                  className="shop-link"
                  to={{
                    pathname: `https://www.google.com/maps/search/${
                      shop.name
                    }/@${shop.latitude},${shop.longitude},${15}z`,
                  }}
                  target="_blank"
                >
                  {shop.formatted_address}
                </Link>
              </div>
            </div>
            {shop.about && (
              <div className="shop-section">
                <h3 className="shop-section-header">ABOUT</h3>
                <p className="shop-section-about">{shop.about}</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SingleShopPage;
