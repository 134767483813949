import React from "react";

const ErrorMessage = ({ show, addHandle, setError }) => {
  return (
    <div
      className={show ? "add-background" : "add-disabled"}
      onClick={() => {
        addHandle();
        setError();
      }}
    >
      <h1 className="add-header">
        We were unable
        <br />
        to process your
        <br />
        request.
        <br />
        Please try again
        <br />
        later!
      </h1>

      <div className="hidden-row"></div>
    </div>
  );
};

export default ErrorMessage;
