import React from "react";
import styled from "styled-components";
import { pinsDescription } from "../map-btn/pinDescription";

const StoreTypeHeader = ({ type }) => {
  return (
    <Container>
      {type && <Pin src={type?.pin_url} alt="pin" />}
      <Title>{pinsDescription.find((pin) => pin.id === type?.id)?.text}</Title>
    </Container>
  );
};

export default StoreTypeHeader;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Pin = styled.img`
  height: 46px;
  width: auto;
  margin-right: 5px;
`;

const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 21px;
  text-align: left;
  padding-top: 10px;
`;
