import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { Image } from "react-bootstrap";

import Api from "../../RestApi";

import ShopsListHeader from "./shopsListHeader";
import SegmentedControl from "./segmentedControl";
import EmptyShop from "../img/pins/sustainable_fashion.png";
import StoreTypeHeader from "./storeTypeHeader";

const ShopsPage = ({ menuCloseHandle }) => {
  const width = window.innerWidth;
  const { city } = useParams();
  const [shops, setShops] = useState();
  const [types, setTypes] = useState();
  const [enabledTypes, setEnableTypes] = useState();
  const [selectedType, setSelectedType] = useState(1);
  useEffect(() => {
    const getShops = async () => {
      const shopsList = await Api.getData(`/api/shops?city=${city}`);
      const shopsId = { shop_id: shopsList.map((shop) => shop?.id) };
      const shopsPictures = await Api.getCityPictures(shopsId);
      const shopsWithPictures = shopsList.map((shop, index) =>
        Object.assign({}, shop, {
          image_file: shopsPictures[index]?.image_file,
        })
      );
      setShops(shopsWithPictures);
    };
    const getTypes = async () => {
      const storeTypes = await Api.getStoreTypes();
      setTypes(storeTypes);
    };
    getShops();
    getTypes();
  }, [city]);

  useEffect(() => {
    const shopTypes = [...new Set(shops?.map((shop) => shop.store_type))];
    const enabledTypes = types?.map((type) => shopTypes.includes(type.id));
    setSelectedType(shopTypes[0]);
    setEnableTypes(enabledTypes);
  }, [shops, types]);

  return (
    <>
      <ShopsListHeader title={city} menuCloseHandle={menuCloseHandle} />
      <SegmentedControl
        types={types}
        enabledTypes={enabledTypes}
        selectedType={selectedType}
        setType={setSelectedType}
      />
      <StoreTypeHeader type={types?.find((type) => type.id === selectedType)} />
      {width > 540 ? (
        <ShopsList shops={shops} city={city} selectedType={selectedType} />
      ) : (
        <MobileShopsList
          shops={shops}
          city={city}
          selectedType={selectedType}
        />
      )}
    </>
  );
};

const MobileShopsList = ({ shops, city, selectedType }) => {
  return (
    <ul id="city-shops-list">
      {shops
        ?.filter((shop) => shop.store_type === selectedType)
        .map((shop) => {
          return (
            <li className="city-shops-items" key={shop?.id}>
              <Image
                className="shops-image"
                src={
                  shop?.image_file
                    ? process.env.REACT_APP_BASE_URL + shop?.image_file
                    : EmptyShop
                }
                alt="Shop's picture"
              />
              <Link className="shops-name" to={`/shops/${city}/${shop?.id}`}>
                {shop?.name}
              </Link>
            </li>
          );
        })}
    </ul>
  );
};

const ShopsList = ({ shops, city, selectedType }) => {
  return (
    <Container>
      {shops
        ?.filter((shop) => shop.store_type === selectedType)
        .map((shop) => {
          return (
            <Column key={shop?.id}>
              <Image
                className="shops-image"
                src={
                  shop?.image_file
                    ? process.env.REACT_APP_BASE_URL + shop?.image_file
                    : EmptyShop
                }
                alt="Shop's picture"
              />
              <Link className="shops-name" to={`/shops/${city}/${shop?.id}`}>
                {shop?.name}
              </Link>
            </Column>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 7.5vw 20px 17.5vw;
`;

const Column = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 25vw;
  margin: 25px 0;
`;
export default ShopsPage;
