import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import SignupForm from "./Subscribe";

import csLogo from "../img/CSLogo_Vector.svg";
import Instagram from "../img/social-media/InstagramBlue.svg";

import "./signup.css";

const SignUpPage = ({ closeHandle }) => {
  return (
    <>
      <Image
        className="sign-up-logo"
        alt="Conscious Sweater logo"
        src={csLogo}
      />
      <h1 id="signup-title">
        Worldwide map of
        <br />
        sustainable fashion shops.
        <br />
        Subscribe to get the latest
        <br />
        news and updates.
      </h1>
      <SignupForm handleSubmitted={closeHandle} />
      <div id="some-div">
        <Link
          className="signup-link"
          to={{ pathname: "https://www.instagram.com/conscious_sweater/" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            className="signup-some"
            alt="Conscious Sweater Instagram"
            src={Instagram}
          />
        </Link>
      </div>
      <div id="hidden-row" />
    </>
  );
};

export default SignUpPage;
