export const CommunityList = [
  {
    text: "Tell your friends about us",
    link: "here",
    pathname: {
      pathname: "https://www.facebook.com/Conscious-Sweater-100714858298427",
    },
    target: "_blank",
  },
  {
    text: "Subscribe to our newsletter",
    link: "here",
    pathname: "/subscribe-to-our-newsletter",
    target: "_parent",
  },
  {
    text: "Add new places in Conscious Sweater map",
    link: "here",
    url: "/",
  },
  {
    text: "Organise your local Conscious Sweater FILM CLUB screening",
    link: "contact us",
    pathname: { pathname: "mailto:veroni@conscioussweater.com" },
    target: "_blank",
  },
  {
    text: "Print & place our Conscious Sweater poster (coming soon)",
  },
  {
    text:
      "Chat with other CS fashionistas in our Slow fashion experience sharing group (coming soon)",
  },
  {
    text: "Share experience with other slow fashion shop owners (coming soon)",
  },
  {
    text: "Donate (coming soon)",
  },
];
