import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import Barbora from "../img/pictures/Bara_BW.png";
import Ester from "../img/pictures/Ester_BW.png";
import Finn from "../img/pictures/Finn.jpg";
import Habiba from "../img/pictures/Habiba.jpg";
import Karolina from "../img/pictures/Kaja_B&W.png";
import Kristina from "../img/pictures/Kristina_B&W.png";
import Mirjam from "../img/pictures/Mirjam_BW.png";
import Mirka from "../img/pictures/Mirka_B&W.png";
import Nila from "../img/pictures/Nila_BW.png";
import Robin from "../img/pictures/Robin.jpg";
import Valentin from "../img/pictures/Valentin_B&W.png";
import Veronika from "../img/pictures/Veronika.jpg";

import useFitText from "../../hooks/useFitText";

import "./TeamMobile.css";

const TeamMobile = () => {
  const classes = Array(TeamMembers.length).fill(false);
  const [activeClasses, setActiveClasses] = useState(classes);

  function addActiveClass(index) {
    const newClasses = [
      ...classes.slice(0, index),
      !classes[index],
      classes.slice(index + 1),
    ].flat();
    setActiveClasses(newClasses);
  }

  return (
    <Container>
      {TeamMembers.map((member, index) => {
        return (
          <PicturesMobile
            key={index}
            member={member}
            index={index}
            classes={classes}
            activeClasses={activeClasses}
            addActiveClass={addActiveClass}
            setActiveClasses={setActiveClasses}
          />
        );
      })}
    </Container>
  );
};

const PicturesMobile = ({
  member,
  index,
  classes,
  activeClasses,
  addActiveClass,
  setActiveClasses,
}) => {
  const { fontSize, ref } = useFitText(100, 180);

  return (
    <Row className="team-row-mobile">
      <Col ref={ref} style={{ fontSize }}>
        <Image
          className="team-img-mobile"
          alt="Team member picture"
          src={member.src}
          onClick={() => addActiveClass(index)}
        />
        <div
          className="p-visible"
          id={activeClasses[index] ? undefined : "p-disabled"}
          onClick={() => setActiveClasses(classes)}
        >
          {member.text}
        </div>
      </Col>
    </Row>
  );
};

const TeamMembers = [
  {
    src: Veronika,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Veronika</span> is the founder and
        CEO. She started thinking about a map of sustainable fashion shops back
        in 2015 when she lived in London. ‘I was so surprised that no one has
        created it yet that I thought this idea must be waiting for me to make
        it happen!’
      </p>
    ),
  },
  {
    src: Valentin,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Valentin</span> is our developer
        responsible for writing a big part of our web-app code as well as for
        connecting the dots of our initial developers' work. His biggest
        passions are coding & sustainable lifestyle, so according to him
        ‘becoming part of CS was pretty much a no brainer’.
      </p>
    ),
  },
  {
    src: Kristina,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Kristina</span> is a passionate
        graphic designer who's great with all things creative. She's responsible
        for our SoMe feed and other visuals. Interested in branding strategy she
        came to Denmark to study it. Kristina loves the philosophy behind
        Conscious Sweater and only shops for pre-loved clothes!
      </p>
    ),
  },
  {
    src: Mirka,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Mirka</span> designed the visual part
        of our communication on social media as well as the last details of our
        map design. She is thrilled to work on projects with purpose and
        specialises in UI & UX design.
      </p>
    ),
  },
  {
    src: Karolina,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Karolina</span> is in charge of
        Conscious Sweater events including Conscious Sweater FILM CLUB. She’s
        interested in afro dancing, marketing, sustainability & event planning.
        You’ll definitely recognise this woman with big energy and a smile at
        our next one!
      </p>
    ),
  },
  {
    src: Nila,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Nila</span> studied communication in
        Copenhagen; she is in charge of our PR. She is also a passionate writer
        and Conscious Sweater’s voice on social media channels.
      </p>
    ),
  },
  {
    src: Mirjam,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Mirjam</span> was our designer who is
        not only responsible for tuning the design of our map MVP but also for
        the visual part of our communication.
      </p>
    ),
  },

  {
    src: Habiba,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Habiba</span> finished her studies in
        web development. She enjoys coding as anything else & has coded the
        front end part of our map-app.
      </p>
    ),
  },
  {
    src: Finn,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Finn</span> helped with structuring
        the coding & advised us on the web development process.
      </p>
    ),
  },
  {
    src: Barbora,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Barbora</span> studied multimedia in
        Copenhagen and worked on the Conscious Sweater map design & branding.
        She also worked on our initial Conscious Sweater texts.
      </p>
    ),
  },
  {
    src: Ester,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Ester</span> created our visual
        identity and most of the Conscious Sweater map design. She studied
        multimedia in Copenhagen and aims for a career in a cool branding
        agency.
      </p>
    ),
  },
  {
    src: Robin,
    text: (
      <p className="team-p">
        <span className="team-mobile-span">Robin</span> was our initial
        developer who built the very first pieces of the Conscious Sweater map!
      </p>
    ),
  },
];

export default TeamMobile;
