import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";

import AddForm from "./AddForm";

const Add = ({ show, addHandle, formSubmit, setError }) => {
  const innerRef = useRef(null);
  const sendButtonRef = useRef(null);
  const outerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      !innerRef.current.contains(event.target) &&
      !sendButtonRef.current.contains(event.target)
    ) {
      addHandle();
    }
  };

  const width = window.innerWidth;

  useEffect(() => {
    const outerDiv = outerRef.current;
    outerDiv.addEventListener("click", handleClickOutside, true);
    return () => {
      outerDiv.removeEventListener("click", handleClickOutside, true);
    };
  });

  return (
    <div className={show ? "add-background" : "add-disabled"} ref={outerRef}>
      <Container>
        <Row className="mb-sm-4 add-header-row">
          <Col>
            {width > 540 ? (
              <h1 className="add-header">
                add<span className="add-span"> your </span>favourite sustainable
                <br />
                store to the map!
              </h1>
            ) : (
              <h1 className="add-header">
                add<span className="add-span"> your </span>favourite
                <br />
                sustainable store to
                <br />
                the map!
              </h1>
            )}
          </Col>
        </Row>

        <AddForm
          formSubmit={formSubmit}
          setError={setError}
          ref={{ formRef: innerRef, buttonRef: sendButtonRef }}
        />

        <Row className="hidden-row"></Row>
      </Container>
    </div>
  );
};

export default Add;
