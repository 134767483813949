import React, { useState } from "react";

import ThankYou from "./ThankYou";
import Add from "./Add";
import ErrorMessage from "./ErrorMessage";

import "./add.css";

const AddPage = ({ show, isSubmitted, addHandle, formSubmit }) => {
  const [isError, setIsError] = useState(false);
  const setError = () => {
    setIsError(!isError);
  };

  return (
    <>
      {isError ? (
        <ErrorMessage show={show} addHandle={addHandle} setError={setError} />
      ) : isSubmitted ? (
        <ThankYou show={show} addHandle={addHandle} />
      ) : (
        <Add
          show={show}
          addHandle={addHandle}
          formSubmit={formSubmit}
          setError={setError}
        />
      )}
    </>
  );
};

export default AddPage;
