import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import HashLinkObserver from "react-hash-link";

import About from "./About";
import Team from "./Team";
import Sustainability from "./Sustainability";
import Community from "./Community";
import Contact from "./Contact";
import ContactMobile from "./ContactMobile";

import closeCross from "../img/buttons/CrossDouble.svg";
import hamburgerIcon from "../img/buttons/BurgerMenu.svg";

import "./cssection.css";

const CsSection = ({
  menuCloseHandle,
  menuOpenHandle,
  addOpenHandle,
  addCloseHandle,
  addShow,
  isSubmitted,
  formReset,
  formSubmit,
}) => {
  const width = window.innerWidth;
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <>
      <Helmet>
        <title>Conscious Sweater map - Information & Contact</title>
        <meta
          name="description"
          content="Information about Conscious Sweater map, our contact information and team members introduction. Join our sustainable fashion journey."
        />
      </Helmet>
      <HashLinkObserver smoothScroll={true} isPageLoading={isLoading} />
      <Link to="/" title="Our sustainable fashion map">
        <Image
          className="close-cross"
          alt="Conscious Sweater sustainable map"
          src={closeCross}
          onClick={menuCloseHandle}
        />
      </Link>
      <Link to="/" title="Our sustainable fashion map">
        <Image
          className="burger-icon"
          alt="Conscious Sweater menu"
          src={hamburgerIcon}
          onClick={menuOpenHandle}
        />
      </Link>
      <div id="about">
        <About />
      </div>
      <div id="team">
        <Team width={width} setIsLoading={setIsLoading} />
      </div>
      <div id="sustainability">
        <Sustainability width={width} />
      </div>
      <div id="community">
        <Community
          addOpenHandle={addOpenHandle}
          addCloseHandle={addCloseHandle}
          addShow={addShow}
          isSubmitted={isSubmitted}
          formReset={formReset}
          formSubmit={formSubmit}
        />
      </div>
      <div id="contacts">{width > 540 ? <Contact /> : <ContactMobile />}</div>
    </>
  );
};

export default CsSection;
