const SidebarData = [
  {
    title: "About",
    path: "#about",
    cName: "nav-text nav-about",
  },
  {
    title: "Team",
    path: "#team",
    cName: "nav-text nav-about",
  },
  {
    title: "Sustainability",
    path: "#sustainability",
    cName: "nav-text",
  },
  {
    title: "Join the Community",
    path: "#community",
    cName: "nav-text nav-community",
  },

  {
    title: "Contact",
    path: "#contacts",
    cName: "nav-text",
  },
];

export default SidebarData;
