import React, { useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { Container, Row, Col, Image } from "react-bootstrap";

import csLogo from "../img/CSLogo_Vector.svg";
import hamburgerIcon from "../img/buttons/BurgerMenu.svg";
import SearchIcon from "../img/buttons/MagnifyingGlass.svg";

import "@reach/combobox/styles.css";
import "./MapHeader.css";

const MapHeader = ({ panTo, mapReset, isMapCentered, menuHandle }) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  return (
    <Container fluid>
      <Row className="justify-content-between">
        <Col className="search-col">
          <div
            className="search"
            onBlur={() => {
              setTimeout(() => setIsInputFocused(false), 200);
            }}
          >
            {isInputFocused ? (
              <Search panTo={panTo} />
            ) : (
              <Image
                className="search-img"
                alt="search"
                src={SearchIcon}
                onClick={() => setIsInputFocused(true)}
              />
            )}
          </div>
        </Col>
        <Col xs={"auto"} className="logo-col">
          <Image
            id={isInputFocused ? "cs-logo-disabled" : "cs-logo"}
            className={isMapCentered ? "cs-logo" : "cs-logo active"}
            alt="Conscious Sweater logo"
            src={csLogo}
            onClick={mapReset}
          />
        </Col>
        <Col className="menu-col">
          <Image
            className="menu-icon"
            alt="Menu icon"
            src={hamburgerIcon}
            onClick={menuHandle}
          />
        </Col>
      </Row>
    </Container>
  );
};

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 51.499998, lng: () => 11.3833318 },
      radius: 100 * 1000,
    },
    cache: 86400,
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };
  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        className="search-input"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder="where?"
        ref={(input) => input && input.focus()}
      />
      <ComboboxPopover id="autocomplete-popover">
        <ComboboxList>
          {status === "OK" &&
            data.map(({ id, description }) => (
              <ComboboxOption
                className="autocomplete-element"
                key={description}
                value={description}
              />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

export default MapHeader;
