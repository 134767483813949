import React from "react";
import jsonp from "jsonp";

import Form from "react-bootstrap/Form";

import { Formik } from "formik";
import * as Yup from "yup";

export const Status = {
  idle: "IDLE",
  loading: "LOADING",
  success: "SUCCESS",
  error: "ERROR",
};

function toQueryString(params) {
  return Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
}

async function postData(data = {}, onSuccess) {
  const params = toQueryString({
    ...data,
    b_1370b093a0133cc057a64dc37_edd4ef82f3: "",
  });
  var url =
    "https://conscioussweater.us7.list-manage.com/subscribe/post-json?u=1370b093a0133cc057a64dc37&id=edd4ef82f3&" +
    params;
  jsonp(url, { param: "c" }, async (err, data) => {
    if (
      data.result === "success" ||
      data.msg.includes("is already subscribed")
    ) {
      fetch(`${process.env.REACT_APP_BASE_URL}/api/session`, {
        method: "POST",
      });
      onSuccess();
    }
  });
}

const SignupForm = ({ handleSubmitted }) => (
  <Formik
    initialValues={{ email: "" }}
    validationSchema={Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    })}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      postData({ EMAIL: values.email }, handleSubmitted);

      resetForm();
      setSubmitting(false);
    }}
  >
    {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Form.Label htmlFor="inlineFormInputEmail" srOnly>
          Email
        </Form.Label>
        <Form.Control
          type="text"
          name="email"
          id="inlineFormInputEmail"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          placeholder="email"
        />
        <button id="signup-button" type="submit" disabled={errors.email}>
          SEND
        </button>
      </Form>
    )}
  </Formik>
);

export default SignupForm;
