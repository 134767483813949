import React from "react";

import pinHalfSustainable from "../img/pins/PinHalfSustainable.svg";
import pinLocal from "../img/pins/PinLocal.svg";
import pinRental from "../img/pins/PinRental.svg";
import pinSustainable from "../img/pins/PinSustainable.svg";
import pinVintage from "../img/pins/PinVintage.svg";

export const pinsDescription = [
  {
    id: 1,
    src: pinSustainable,
    text: "sustainable fashion shop",
    susText: (
      <p className="pin-p">
        <span className="bold-span">Sustainable fashion shops</span>, boutiques,
        brands & designers
        <br />
        focused on selling brand new clothes made in an ecological
        <br />& ethical way.
      </p>
    ),
    textMobile: (
      <p className="pin-p">
        <span className="bold-span">Sustainable fashion shops</span>, boutiques,
        brands & designers focused on selling brand new clothes made in an
        ecological & ethical way.
      </p>
    ),
    alt: "Sustainable pin",
  },
  {
    id: 2,
    src: pinVintage,
    text: "vintage shop",
    susText: (
      <p className="pin-p">
        <span className="bold-span">Vintage shops</span> sell already used,
        pre-loved clothes.
      </p>
    ),
    textMobile: (
      <p className="pin-p">
        <span className="bold-span">Vintage shops</span> sell already used,
        pre-loved clothes.
      </p>
    ),
    alt: "Vintage pin",
  },
  {
    id: 4,
    src: pinRental,
    text: "rental service",
    susText: (
      <p className="pin-p">
        <span className="bold-span">Rental services</span> offer clothes used
        multiple times by
        <br />
        multiple people.
      </p>
    ),
    textMobile: (
      <p className="pin-p">
        <span className="bold-span">Rental services</span> offer clothes used
        multiple times by multiple people.
      </p>
    ),
    alt: "Rental pin",
  },
  {
    id: 5,
    src: pinLocal,
    text: "local designer shop",
    susText: (
      <p className="pin-p">
        <span className="bold-span">Local fashion</span> designed and
        produced(!) both locally
        <br />& ecologically.
      </p>
    ),
    textMobile: (
      <p className="pin-p">
        <span className="bold-span">Local fashion</span> designed and
        produced(!) both locally & ecologically.
      </p>
    ),
    alt: "Local pin",
  },
  {
    id: 3,
    src: pinHalfSustainable,
    text: "shop selling some sustainable brands",
    susText: (
      <p className="pin-p">
        <span className="bold-span">Shops selling some sustainable brands</span>{" "}
        choose to offer
        <br />
        selected sustainable clothes even though their core value is
        <br />
        not sustainability.
      </p>
    ),
    textMobile: (
      <p className="pin-p">
        <span className="bold-span">Shops selling some sustainable brands</span>{" "}
        choose to offer selected sustainable clothes even though their core
        value is not sustainability.
      </p>
    ),
    alt: "Half sustainable pin",
  },
];
