import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import CSRFToken from "../utils/CSRFToken";

const AddForm = React.forwardRef(({ formSubmit, setError }, ref) => {
  const { formRef, buttonRef } = ref;
  const [boxIsVisible, setBoxIsVisible] = useState(false);
  const fetchURL = `${process.env.REACT_APP_BASE_URL}/api/user-request`;

  function checkboxClick() {
    setBoxIsVisible(!boxIsVisible);
  }

  return (
    <Formik
      initialValues={{
        store_name: "",
        website: "",
        categories: [],
        address: "",
      }}
      validationSchema={Yup.object({
        store_name: Yup.string().max(50, "Name too long").required("Required"),
        website: Yup.string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
          )
          .required("Required"),
        address: Yup.string()
          .min(5, "Address too short")
          .max(80, "Address too long"),
      })}
      onSubmit={(values, { resetForm }) => {
        fetch(fetchURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          body: JSON.stringify(values),
        })
          .then((res) => {
            res.json();
            if (res.status >= 400) {
              throw new Error("Error");
            }
          })
          .then(() => formSubmit())
          .catch(() => {
            resetForm();
            setError();
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <CSRFToken />
          <div className="form-div" ref={formRef}>
            <Row className="justify-content-center add-form-row">
              <Col xs={12} sm={11} className="store-col">
                <Form.Label htmlFor="FormInputStore" srOnly>
                  Store name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="store_name"
                  id="FormInputStore"
                  className="add-store-form"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.store_name}
                  placeholder="name of the store*"
                />
              </Col>
            </Row>

            <Row className="justify-content-center add-form-row">
              <Col xs={12} sm={11} className="store-col">
                <Form.Label htmlFor="FormInputWebsite" srOnly>
                  Website
                </Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  id="FormInputWebsite"
                  className="add-store-form"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.website}
                  placeholder="website*"
                />
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs={12} sm={11} className="store-col">
                <Form.Label htmlFor="FormCheckboxRange" srOnly>
                  Range
                </Form.Label>
                <Form.Control
                  type="text"
                  id={
                    boxIsVisible
                      ? "FormCheckboxRangeActive"
                      : "FormCheckboxRange"
                  }
                  className="add-store-form"
                  onClick={(e) => {
                    e.preventDefault();
                    checkboxClick();
                  }}
                  placeholder="range"
                  aria-label="button"
                  readOnly
                />
              </Col>
            </Row>

            <CheckboxForm
              boxIsVisible={boxIsVisible}
              handleChange={handleChange}
            />

            <Row className="justify-content-center">
              <Col xs={12} sm={11} className="store-col">
                <Form.Label htmlFor="FormInputAddress" srOnly>
                  Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  className="add-store-form"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  placeholder="address"
                />
              </Col>
            </Row>
          </div>

          <div className="form-div">
            <Row className="justify-content-center add-form-row">
              <Col
                xs={7}
                className={boxIsVisible ? "submit-col-active" : "submit-col"}
              >
                <Button
                  ref={buttonRef}
                  type="submit"
                  disabled={
                    (errors.store_name && touched.store_name) ||
                    (errors.website && touched.website) ||
                    isSubmitting
                  }
                  block
                >
                  SEND
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
});

const CheckboxForm = ({ boxIsVisible, handleChange }) => {
  return (
    <Row className={boxIsVisible ? "" : "add-disabled"}>
      <Col className="checkbox-col">
        <Form.Check
          inline
          name="categories"
          value="women"
          onChange={handleChange}
          label="women"
          type="checkbox"
          className="inline-checkbox"
        />
        <Form.Check
          inline
          name="categories"
          value="men"
          onChange={handleChange}
          label="men"
          type="checkbox"
          className="inline-checkbox"
        />
        <Form.Check
          inline
          name="categories"
          value="children"
          onChange={handleChange}
          label="children"
          type="checkbox"
          className="inline-checkbox"
        />
        <Form.Check
          inline
          name="categories"
          value="concept store"
          onChange={handleChange}
          label="concept store"
          type="checkbox"
          className="inline-checkbox"
        />
      </Col>
    </Row>
  );
};

export default AddForm;
