import React from "react";
import { Link } from "react-router-dom";
import HashLinkObserver from "react-hash-link";

const CitiesListMobile = ({ alphabet, cities }) => {
  return (
    <>
      <HashLinkObserver smoothScroll={true} />
      <ul id="cities-list">
        {alphabet.map((letter) => {
          return (
            <div id={letter} key={letter}>
              {/* Filter cities by their first letter to diplay them in the div with the same letter */}
              {cities
                .filter((item) => item.city.toUpperCase().startsWith(letter))
                .map((item) => {
                  return (
                    <li key={item.city}>
                      <Link
                        className="city-name"
                        to={`/shops/${item.city.toLowerCase()}`}
                      >
                        {item.city}
                      </Link>
                    </li>
                  );
                })}
            </div>
          );
        })}
      </ul>
    </>
  );
};

export default CitiesListMobile;
