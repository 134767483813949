import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";

import Instagram from "../img/social-media/InstagramLavender.svg";
import Facebook from "../img/social-media/FacebookLavender.svg";
import Linkedin from "../img/social-media/LinkedinLavender.svg";

const ContactMobile = () => {
  return (
    <>
      <h1 className="section-header">Contact</h1>

      <h2 className="section-h2">WE WANT TO HEAR FROM YOU!</h2>

      <p className="center-p">
        Please let us know your feedback on our Conscious Sweater map at
        <br />
        <a
          className="contact-mail"
          href="mailto:hi@conscioussweater.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          hi@conscioussweater.com
        </a>
      </p>

      <p className="center-p">
        Would you like to collaborate with CS?
        <br />
        Tell us what you do at{" "}
        <a
          className="contact-mail"
          href="mailto:volunteer@conscioussweater.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          volunteer@conscioussweater.com
        </a>
      </p>

      <p className="center-p">
        Do you want to follow us?
        <br />
        Find us here:
      </p>

      <Container>
        <Row className="justify-content-center">
          {MediaList.map((media, index) => {
            return (
              <Col xs={"auto"} className="contact-col" key={index}>
                <Link
                  to={{ pathname: media.url }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="contact-img"
                    alt={media.name}
                    src={media.src}
                  />
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

const MediaList = [
  {
    name: "Instagram",
    src: Instagram,
    url: "https://www.instagram.com/conscious_sweater/",
  },
  {
    name: "Facebook",
    src: Facebook,
    url: "https://www.facebook.com/Conscious-Sweater-100714858298427",
  },
  {
    name: "LinkedIn",
    src: Linkedin,
    url: "https://www.linkedin.com/company/conscious-sweater/",
  },
];

export default ContactMobile;
