import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { pinsDescription } from "./pinDescription";

import "./help.css";

const Help = ({ show, helpHandle }) => {
  return (
    <div
      className={show ? "help-background" : "help-disabled"}
      onClick={helpHandle}
    >
      <Container fluid>
        <Row className="mb-sm-4 help-header-row">
          <Col>
            <h1 className="help-header">
              Which pin is <br />
              for <span className="help-span">you</span>?
            </h1>
          </Col>
        </Row>
        {pinsDescription.map((pin, index) => {
          return (
            <Row
              className="mb-sm-1 help-row justify-content-center"
              key={index}
            >
              <Col className="help-pin" xs={"auto"} sm={1}>
                <Image className="help-img" alt={pin.alt} src={pin.src} />
              </Col>
              <Col
                className="align-left d-flex align-items-center"
                xs={6}
                sm={3}
              >
                <p className="help-text">{pin.text}</p>
              </Col>
            </Row>
          );
        })}
        <Row className="hidden-row"></Row>
      </Container>
    </div>
  );
};

export default Help;
