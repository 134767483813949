import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import Barbora from "../img/pictures/Bara_BW.png";
import Ester from "../img/pictures/Ester_BW.png";
import Finn from "../img/pictures/Finn.jpg";
import Habiba from "../img/pictures/Habiba.jpg";
import Karolina from "../img/pictures/Kaja_B&W.png";
import Kristina from "../img/pictures/Kristina_B&W.png";
import Mirjam from "../img/pictures/Mirjam_BW.png";
import Mirka from "../img/pictures/Mirka_B&W.png";
import Nila from "../img/pictures/Nila_BW.png";
import Robin from "../img/pictures/Robin.jpg";
import Valentin from "../img/pictures/Valentin_B&W.png";
import Veronika from "../img/pictures/Veronika.jpg";

import "./TeamDesktop.css";

const TeamDesktop = () => {
  return (
    <Container>
      <Row className="row-team">
        <Col>
          <Image
            className="team-img"
            alt="Team member picture"
            src={Veronika}
          />
        </Col>
        <Col>
          <Image
            className="team-img"
            alt="Team member picture"
            src={Valentin}
          />
        </Col>
        <Col>
          <Image
            className="team-img"
            alt="Team member picture"
            src={Kristina}
          />
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <p className="team-p">
            <b>Veronika</b> is the founder and CEO. She started thinking about a
            map of sustainable fashion shops back in 2015 when she lived in
            London. ‘I was so surprised that no one has created it yet that I
            thought this idea must be waiting for me to make it happen!’
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Valentin</b> is our developer responsible for writing a big part
            of our web-app code as well as for connecting the dots of our
            initial developers' work. His biggest passions are coding &
            sustainable lifestyle, so according to him ‘becoming part of CS was
            pretty much a no brainer’.
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Kristina</b> is a passionate graphic designer who's great with
            all things creative. She's responsible for our SoMe feed and other
            visuals. Interested in branding strategy she came to Denmark to
            study it. Kristina loves the philosophy behind Conscious Sweater and
            only shops for pre-loved clothes!
          </p>
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <Image className="team-img" alt="Team member picture" src={Mirka} />
        </Col>
        <Col>
          <Image
            className="team-img"
            alt="Team member picture"
            src={Karolina}
          />
        </Col>
        <Col>
          <Image className="team-img" alt="Team member picture" src={Nila} />
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <p className="team-p">
            <b>Mirka</b> designed the visual part of our communication on social
            media as well as the last details of our map design. She is thrilled
            to work on projects with purpose and specialises in UI & UX design.
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Karolina</b> is in charge of Conscious Sweater events including
            Conscious Sweater FILM CLUB. She’s interested in afro dancing,
            marketing, sustainability & event planning. You’ll definitely
            recognise this woman with big energy and a smile at our next one!
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Nila</b> studied communication in Copenhagen; she is in charge of
            our PR. She is also a passionate writer and Conscious Sweater’s
            voice on social media channels.
          </p>
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <Image className="team-img" alt="Team member picture" src={Mirjam} />
        </Col>
        <Col>
          <Image className="team-img" alt="Team member picture" src={Habiba} />
        </Col>
        <Col>
          <Image className="team-img" alt="Team member picture" src={Finn} />
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <p className="team-p">
            <b>Mirjam</b> was our designer who is not only responsible for
            tuning the design of our map MVP but also for the visual part of our
            communication.
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Habiba</b> finished her studies in web development. She enjoys
            coding as anything else & has coded the front end part of our
            map-app.
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Finn</b> helped with structuring the coding & advised us on the
            web development process.
          </p>
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <Image className="team-img" alt="Team member picture" src={Barbora} />
        </Col>
        <Col>
          <Image className="team-img" alt="Team member picture" src={Ester} />
        </Col>
        <Col>
          <Image className="team-img" alt="Team member picture" src={Robin} />
        </Col>
      </Row>
      <Row className="row-team">
        <Col>
          <p className="team-p">
            <b>Barbora</b> studied multimedia in Copenhagen and worked on the
            Conscious Sweater map design & branding. She also worked on our
            initial Conscious Sweater texts.
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Ester</b> created our visual identity and most of the Conscious
            Sweater map design. She studied multimedia in Copenhagen and aims
            for a career in a cool branding agency.
          </p>
        </Col>
        <Col>
          <p className="team-p">
            <b>Robin</b> was our initial developer who built the very first
            pieces of the Conscious Sweater map!
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamDesktop;
