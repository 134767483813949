import React from "react";
import { Link } from "react-router-dom";

import { CommunityList } from "./CommunityList";
import AddCommu from "../map-btn/AddCommunity";

import "./Community.css";

const Community = ({
  addOpenHandle,
  addCloseHandle,
  addShow,
  isSubmitted,
  formReset,
  formSubmit,
}) => {
  return (
    <>
      <h1 className="section-header">Join the community</h1>
      <p className="section-p">
        We took our time and energy to create the map we wanted to exist. To be
        able to give it to you - beautiful, functional, with verified
        information and for free - we spent months and months voluntarily
        designing, developing, communicating and organising. We very much
        enjoyed the ride and would love to get{" "}
        <span className="bold-span">you</span> onboard now.
      </p>
      <p className="section-p">
        By community we don’t only think of us & you. Even though this
        connection is very important for us and we’d like to hear from you and
        discuss sustainability in fashion. We also mean your own community. We
        literally want to include your friends & network too.
      </p>

      <h2 className="section-h2">
        HOW TO ACTIVELY JOIN CONSCIOUS SWEATER COMMUNITY:
      </h2>

      <ul className="community-list">
        {CommunityList.map((item, index) => {
          return (
            <li key={index}>
              <p className="section-p">
                {item.text}{" "}
                {item.pathname ? (
                  <Link
                    className="community-link"
                    to={item.pathname}
                    target={item.target}
                    rel="noopener noreferrer"
                  >
                    {item.link}
                  </Link>
                ) : (
                  item.link && (
                    <Link
                      className="community-link"
                      to="#community"
                      onClick={() => {
                        addOpenHandle();
                        formReset();
                      }}
                    >
                      {item.link}
                    </Link>
                  )
                )}
              </p>
            </li>
          );
        })}
      </ul>
      <AddCommu
        show={addShow}
        isSubmitted={isSubmitted}
        addCloseHandle={addCloseHandle}
        formSubmit={formSubmit}
      />
    </>
  );
};

export default Community;
