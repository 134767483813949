class Api {
  static async getMarkers() {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/markers`
    );
    const data = await response.json();
    if (response.status >= 400) throw Error(data.message);
    return data;
  }

  static async getPhotos(shop_id) {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/markers-photos?shop-id=${shop_id}`
    );
    const data = await response.json();
    if (response.status >= 400) throw Error(data.message);
    return data;
  }

  static async getHours(shopId) {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/markers-hours?shop-id=${shopId}`
    );
    const data = await response.json();
    if (response.status >= 400) throw Error(data.message);
    return data;
  }

  static async getData(url) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`);
    const data = await response.json();
    if (response.status >= 400) throw Error(data.message);
    return data;
  }

  static async getCityPictures(shops_id) {
    function objToQueryString(param, list) {
      const keyValuePair = [];
      list.forEach((shop_id) => {
        keyValuePair.push(param + "=" + shop_id);
      });

      return keyValuePair.join("&");
    }
    const queryString = objToQueryString("shop_id", shops_id.shop_id);
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/shops-pictures?${queryString}`
    );
    const data = await response.json();
    if (response.status >= 400) throw Error(data.message);
    return data;
  }

  static async getStoreTypes() {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/store_types`
    );
    const data = await response.json();
    if (response.status >= 400) throw Error(data.message);
    return data;
  }
}

export default Api;
