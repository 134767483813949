import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import csLogo from "../img/CSLogo_Vector.svg";
import Instagram from "../img/social-media/InstagramBlue.svg";

import "./signup.css";

const SubscribeThankYou = () => {
  return (
    <>
      <Image
        className="sign-up-logo"
        alt="Conscious Sweater logo"
        src={csLogo}
      />
      <h1 id="signup-title">
        Thank you for becoming
        <br />
        part of the Conscious <br />
        Sweater Community. <br />
        We will be in touch!{" "}
      </h1>
      <div id="some-div">
        <Link
          className="signup-link"
          to={{ pathname: "https://www.instagram.com/conscious_sweater/" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            className="signup-some"
            alt="Conscious Sweater Instagram"
            src={Instagram}
          />
        </Link>
      </div>
      <div id="hidden-row" />
    </>
  );
};

export default SubscribeThankYou;
