import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import AddFormCommu from "./AddFormCommu";

import closeCross from "../img/buttons/CrossDouble.svg";

import "./add.css";

const AddCommu = ({ show, isSubmitted, addCloseHandle, formSubmit }) => {
  const width = window.innerWidth;
  const [isError, setIsError] = useState(false);
  const setError = () => {
    setIsError(!isError);
  };
  return (
    <div className={show ? "add-community" : "add-disabled"}>
      <Image
        className="close-add-commu"
        alt="close"
        src={closeCross}
        onClick={() => {
          isError && setError();
          addCloseHandle();
        }}
      />
      {isError ? (
        <ErrorMessage />
      ) : isSubmitted ? (
        <ThankYou width={width} />
      ) : (
        <Add formSubmit={formSubmit} width={width} setError={setError} />
      )}
      <div className="hidden-row" />
    </div>
  );
};

const Add = ({ formSubmit, width, setError }) => {
  return (
    <Container>
      <Row className="mb-sm-4 add-header-row">
        <Col>
          {width > 540 ? (
            <h1 className="add-header">
              add<span className="add-span"> your </span>favourite sustainable
              <br />
              store to the map!
            </h1>
          ) : (
            <h1 className="add-header">
              add<span className="add-span"> your </span>favourite
              <br />
              sustainable store to
              <br />
              the map!
            </h1>
          )}
        </Col>
      </Row>
      <div className="form-div">
        <AddFormCommu formSubmit={formSubmit} setError={setError} />
      </div>
    </Container>
  );
};

const ThankYou = ({ width }) => {
  return (
    <>
      {width > 540 ? (
        <h1 className="add-header">
          thank you for
          <br />
          investing your time
          <br />
          and helping others
          <br />
          to shop more
          <br />
          consciously!
        </h1>
      ) : (
        <h1 className="add-header thanks-header">
          thank you
          <br />
          for investing
          <br />
          your time
          <br />
          and helping
          <br />
          others to
          <br />
          shop more
          <br />
          consciously!
        </h1>
      )}
    </>
  );
};

const ErrorMessage = () => {
  return (
    <>
      <h1 className="add-header">
        We were unable
        <br />
        to process your
        <br />
        request.
        <br />
        Please try again
        <br />
        later!
      </h1>

      <div className="hidden-row"></div>
    </>
  );
};

export default AddCommu;
