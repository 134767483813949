import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

import Api from "../../RestApi";

import CitiesPage from "./citiesPage";
import ShopsPage from "./shopsPage";
import SingleShopPage from "./singleShopPage";

import "./cities-page.css";

const ShopsListUrls = ({ menuCloseHandle }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCities = async () => {
      const data = await Api.getData("/api/cities");
      const cities = data.filter((city) => city.city !== "");
      setCities(cities);
    };
    getCities();
  }, []);

  return (
    <div id="cities-bg">
      <Route
        exact
        path="/shops"
        render={() => (
          <CitiesPage cities={cities} menuCloseHandle={menuCloseHandle} />
        )}
      />
      <Route
        exact
        path="/shops/:city"
        render={() => <ShopsPage menuCloseHandle={menuCloseHandle} />}
      />
      {/* <ShopsPage />
      </Route> */}
      <Route
        path="/shops/:city/:id"
        render={() => <SingleShopPage menuCloseHandle={menuCloseHandle} />}
      />
      {/* <SingleShopPage />
      </Route> */}
    </div>
  );
};

export default ShopsListUrls;
