import React from "react";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";

import HoursDiplay from "./hoursDisplay";

import NavBlue from "../img/nav/NavigationBlue.svg";
import NavLavender from "../img/nav/NavigationLavender.svg";
import HoursBlue from "../img/nav/OpeningHoursBlue.svg";
import HoursLavender from "../img/nav/OpeningHoursLavender.svg";
import WebBlue from "../img/nav/WebsiteBlue.svg";
import WebLavender from "../img/nav/WebsiteLavender.svg";
import CloseBlue from "../img/nav/CloseBlue.svg";
import CloseLavender from "../img/nav/CloseLavender.svg";
import ArrowBlue from "../img/nav/Arrowblue.svg";
import ArrowLavender from "../img/nav/Arrowlavender.svg";

import EmptyShop from "../img/pins/sustainable_fashion.png";

const NewInfoWindow = ({ shop, color, photos, closeClick }) => {
  const websiteIndex = shop.website.indexOf(".com");
  return (
    <>
      <div
        id={
          color === "blue"
            ? "info-blue"
            : color === "lavender"
            ? "info-lavender"
            : "info-brown"
        }
      >
        <Image
          id="pop-up-close"
          src={color === "lavender" ? CloseBlue : CloseLavender}
          alt="close"
          onClick={closeClick}
        />
        <h1 id="store-name">{shop.name}</h1>

        {shop.location && <h2 id="store-location">{shop.location}</h2>}
        <h2 id="store-description">{shop.description}</h2>
        <Carousel id="store-carousel" interval={null}>
          {photos.length ? (
            Object.keys(photos).map((imageKey, index) => (
              <Carousel.Item className="carousel-item" key={index}>
                <Image
                  className="store-image"
                  src={
                    process.env.REACT_APP_BASE_URL + photos[imageKey].image_file
                  }
                  alt="shop-picture"
                />
              </Carousel.Item>
            ))
          ) : (
            <Carousel.Item className="carousel-item">
              <Image
                className="store-image"
                src={EmptyShop}
                alt="shop-picture"
              />
            </Carousel.Item>
          )}
        </Carousel>
        <div
          className={
            shop.hours.status === "error" ? "store-wrapper" : "hours-wrapper"
          }
        >
          <Image
            className="pop-up-nav"
            src={color === "lavender" ? HoursBlue : HoursLavender}
            alt="clock"
          />
          {shop.hours.status === "error" ? (
            <p className="hours-p">Hours not available</p>
          ) : (
            <HoursDiplay
              shopHours={shop.hours}
              src={color === "lavender" ? ArrowBlue : ArrowLavender}
            />
          )}
        </div>
        <div className="store-wrapper">
          <Image
            className="pop-up-nav"
            src={color === "lavender" ? WebBlue : WebLavender}
            alt="website"
          />
          <Link
            className="pop-up-link"
            to={{ pathname: `https://${shop.website}` }}
            target="_blank"
          >
            {websiteIndex !== -1
              ? shop.website.slice(0, websiteIndex + 4)
              : shop.website}
          </Link>
        </div>
        <div className="store-wrapper">
          <Image
            className="pop-up-nav"
            src={color === "lavender" ? NavBlue : NavLavender}
            alt="navigation"
          />
          <Link
            className="pop-up-link"
            to={{
              pathname: `https://www.google.com/maps/search/${shop.name}/@${
                shop.latitude
              },${shop.longitude},${13}z`,
            }}
            target="_blank"
          >
            {shop.formatted_address}
          </Link>
        </div>
        <p className="audiences-p">
          {shop.audiences.map((audience, i) => [i > 0 && " | ", audience])}
        </p>
      </div>
      <div
        id={
          color === "blue"
            ? "info-after-blue"
            : color === "lavender"
            ? "info-after-lavender"
            : "info-after-brown"
        }
      />
    </>
  );
};

export default NewInfoWindow;
