import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import ArrowBack from "../img/buttons/ArrowUpperBar.svg";
import MapIcon from "../img/buttons/MapIcon.svg";

const ShopsListHeader = ({ title, menuCloseHandle }) => {
  const history = useHistory();
  return (
    <div id="header-container">
      <div>
        <Image
          id="header-arrow-back"
          src={ArrowBack}
          onClick={() => history.goBack()}
        />
      </div>
      <div>
        <h1 id="header-title">{title}</h1>
      </div>
      <div id="header-map-icon">
        <Link
          to="/"
          title="Our sustainable fashion map"
          onClick={menuCloseHandle}
        >
          <Image id="header-map-img" src={MapIcon} />
          <p id="header-map">map</p>
        </Link>
      </div>
    </div>
  );
};

export default ShopsListHeader;
