import React from "react";

import TeamDesktop from "./TeamPicturesDesktop";
import TeamMobile from "./TeamPicturesMobile";

const Team = ({ width, setIsLoading }) => {
  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  });

  return (
    <>
      <h1 className="section-header">Team</h1>
      <h2 className="section-h2">WHO KNITS THE CONSCIOUS SWEATER?</h2>
      <p className="section-p">
        We are a Copenhagen-based group of sustainable fashion enthusiasts who
        decided to give back and actively join the sustainable fashion movement.
        We are the team that wears Conscious Sweater:
      </p>
      {width > 540 ? (
        <TeamDesktop setIsLoading={setIsLoading} />
      ) : (
        <TeamMobile setIsLoading={setIsLoading} />
      )}
    </>
  );
};

export default Team;
